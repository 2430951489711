import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { currencyInterface, GeoCurrency, PaymentsVerification, withdrawDescription } from '../../interfaces/interfaces';
import { HelpersService } from '../helpers/helpers.service';
import { LimitPeriod, ResultDate, TypeLimit } from '../../interfaces/interfaces';
import { DateBuilder } from '../../utils/date-builder';
import { forkJoin, of } from 'rxjs';
import { KeystoneService } from '../keystone/keystone.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class FinancesService {
  makeDepositRegistration: EventEmitter<any> = new EventEmitter<any>();
  iframeDepositOutput: EventEmitter<any> = new EventEmitter<any>();

  cashforoUrls: any = {
    prod: 'https://cashforo.com',
    test: 'https://staging-cashforo.com',
  };
  constructor(
    private http: HttpClient,
    private navigator: NavigationService,
    private helpers: HelpersService,
    private keystone: KeystoneService,
    private storage: LocalStorageService
  ) {}
  payment: EventEmitter<any> = new EventEmitter<any>();

  filterValidPayments(data: any, paymentsToFind: PaymentsVerification[], isVerified?: boolean) {
    return data.filter((element: any) => {
      const description = this.helpers.jsonConvert(element?.description_withdraw);
      const isSafe = description?.safe;
      return isSafe || isVerified;
    });
  }

  filterNotValidPayments(data: any, paymentsToFind: PaymentsVerification[], isVerified?: boolean) {
    if (paymentsToFind.length !== 0) {
      return data;
    }
    return data.filter((element: any) => {
      const description = this.helpers.jsonConvert(element?.description_withdraw);
      const isSafe = !description?.safe;
      // ----
      const isVerifiedPayment = isVerified ? paymentsToFind.length !== 0 : true;
      return isSafe && isVerifiedPayment;
    });
  }

  filterPaymentsBySlug(payments: any[], slug: string) {
    if (slug === 'all') {
      return payments;
    }
    return payments.filter((payment: any) => payment.tags.includes(slug));
  }

  filterCryptoPayments(payments: any, selectedBonusId: any = null) {
    const params: any = this.navigator.getQueryParams();
    if (params.bonus == '72178' || selectedBonusId == '72178') {
      payments = payments.filter((elem: any) => elem.tags.includes('crypto'));
    }
    return payments;
  }

  getWithdrawsTransactions() {
    return this.http.get('/transactions?status=0');
  }

  deleteWithdrawTransaction(id: string) {
    return this.http.delete(`/withdrawals?id=${id}`);
  }

  getPaymentMethods(source: 'deposit' | 'withdraw' | 'all' = 'all') {
    return this.http.get<any[]>(`/paymentSystems?lang=en`).pipe(
      map((obj: any) => {
        const result: any = [];
        if (source === 'all') {
          return obj;
        }
        obj.map((element: any) => {
          if (element?.showfor.toLowerCase().indexOf(source) > -1 || element?.showfor.toLowerCase() === 'all') {
            result.push(element);
          }
        });
        return result;
      })
    );
  }

  getCurrencies() {
    return this.http.get('/currencies').pipe(
      map((obj: any) => {
        const result: any = [];
        const newobj = {
          ...obj,
        };
        if (Array.isArray(newobj)) {
          obj.forEach((element: any) => {
            if (element?.registration) {
              result.push({
                value: element.Name,
                title: element.Name,
                ...element,
              });
            }
          });
          return result;
        } else {
          for (const i in obj) {
            if (obj[i]?.registration) {
              result.push({
                value: obj[i]?.Name,
                title: obj[i]?.Name,
                ...obj[i],
              });
            }
          }
          return result;
        }
      })
    );
  }

  getWithdrawLimits(method: string[], currency: string, amount: number, userId: string) {
    return this.http.post('/api/v2/withdraw-limits/check-limits', {
      method: method.join(','),
      currency,
      merchantId: '23848259', // Welle merchantId
      amount,
      userId,
    });
  }

  getLimitsDate(period: LimitPeriod): { startDate: Date; endDate: Date } | null {
    let date: { startDate: Date; endDate: Date } | null = null;
    if (period?.type == TypeLimit.DATE_TO_DATE) {
      date = new DateBuilder('')
        .skipByUnit(period.skip_by_unit, period.skip_unit)
        .takeByUnit(period.take_by_unit, period.take_unit)
        .setPoint(period.start_point, period.start_date_unit, ResultDate.START_DATE)
        .setPoint(period.end_point, period.end_date_unit, ResultDate.END_DATE)
        .returnDate();
    }
    if (period?.type == TypeLimit.FROM_NOW) {
      date = new DateBuilder('')
        .skipByUnit(period.skip_by_unit, period.skip_unit)
        .takeByUnit(period.take_by_unit, period.take_unit)
        .returnDate();
    }
    if (!period?.type) throw new Error('No period type specified');
    return date;
  }

  getReefBack(from: string, to: string, amount: number) {
    return this.http.post('/api/v2/exchange/exchange', {
      from,
      to,
      amount,
    });
  }

  deposit(paymentId: string, amount: number, additionalFields?: any) {
    const params: any = {
      systemId: paymentId,
      amount: amount,
      additional: {
        ...additionalFields,
      },
    };
    return this.http.post('/deposits', params);
  }
  depositColibrix(transactionId: string, cashforoParams: any, publicTransactionId: string) {
    return this.http.post('https://cashforo.com/zcolibrix-adapter/charge', {
      transactionId,
      publicTransactionId,
      ...cashforoParams,
    });
  }

  withdraw(payment: any, amount: number, additionalFields: any) {
    const params: any = {
      systemId: payment?.id,
      amount: amount,
      additional: {
        ...additionalFields,
      },
    };
    return this.http.post('/withdrawals?GetID=1', params);
  }
  setIframeDepositUrl(url: string) {
    this.iframeDepositOutput.emit(url);
  }
  postToCashforo(withdrawID: string, externalWithdrawId: string) {
    return this.http.post(`https://cashforo.com/main/withdraw-flat/update-external-id`, {
      withdrawId: `${withdrawID}`,
      externalWithdrawId: `${externalWithdrawId}`,
    });
  }
  canceledWithdrawal(data: { externalWithdrawId: string; isCanceledByUser?: boolean }) {
    return this.http.post(`https://cashforo.com/main/withdraw-flat/cancel`, {
      ...data,
    });
  }
  setAmount(amount: string) {
    return Number(amount).toFixed(2);
  }

  setDate(date: string) {
    return date.split(' ')[1];
  }
  filterDepBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Event?.indexOf('deposit') > -1);
  }
  sendPaymentMethod(payment: any) {
    this.payment.emit(payment);
  }
  sendDepositData(params: any) {
    this.makeDepositRegistration.emit(params);
  }

  setSortedCurrencies(country: string) {
    forkJoin({
      currencies: this.getCurrencies().pipe(
        filter((currencies: currencyInterface[]) => !!currencies && Object.keys(currencies).length > 0),
        take(1)
      ),
      geoCurrencies: this.keystone.getGeoCurrenciesBySlug(country).pipe(
        switchMap((response) => (response ? of(response) : this.keystone.getGeoCurrenciesBySlug('default'))),
        catchError(() => of(null)),
        take(1)
      ),
    }).subscribe(({ currencies, geoCurrencies }) => {
      if (!geoCurrencies) {
        this.storage.setSessionStorage('sortCurrencies', JSON.stringify(currencies));
      } else {
        this.sortCurrencies(currencies, geoCurrencies);
      }
    });
  }

  sortCurrencies(currencies: currencyInterface[], geoCurrencies: GeoCurrency) {
    const { excludeCurrencies = [], priorCurrencies = [] } = geoCurrencies;
    let filteredCurrencies = currencies.filter((currency) => !excludeCurrencies.includes(currency.Name));
    if (priorCurrencies.length > 0) {
      const priorSorted = priorCurrencies
        .map((prior: string) => filteredCurrencies.find((currency) => currency.Name === prior))
        .filter((currency): currency is currencyInterface => !!currency);
      const nonPriorCurrencies = filteredCurrencies.filter((currency) => !priorCurrencies.includes(currency.Name));
      filteredCurrencies = [...priorSorted, ...nonPriorCurrencies];
    }
    this.storage.setSessionStorage('sortCurrencies', JSON.stringify(filteredCurrencies));
  }

  checkWithdrawDescription(params: any): withdrawDescription {
    if (typeof params === 'object') {
      const control = Object.keys(params)[0];
      return { control: control, labelWithoutTranslation: params[control] };
    }
    return { control: params, labelWithoutTranslation: `cashier.payments.lebels.${params}` };
  }
}
